import React, { Component } from 'react';
import introLogo from './Logo-300x277.png';
import bgLogo from './bg_logo.png';
import Paper from '@material-ui/core/Paper';
import './App.css';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import {
  green,
  amber,
  blueGrey,
} from '@material-ui/core/colors';

import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Countdown from './Countdown';

const theme = createMuiTheme({
  palette: {
    primary: blueGrey,
    secondary: amber,
  },
});

const deadline = '2021-04-30T19:47:00';

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      showCountdown: false,
    };
  };

  componentDidMount() {
    this.showIntro();

    const diff = (Date.parse(new Date(deadline)) - Date.parse(new Date())) / 1000;
    console.log(diff)
    if (diff > 0) {
      this.setState({
        showCountdown: true,
      });
      setTimeout(() => {
        this.setState({ showCountdown: false});
      }, diff*1000)
    }
  }

  showIntro() {
    this.setState({
      loading: true,
    });

    // setTimeout(() => {
    //   this.setState({
    //     loading: false,
    //   })
    // }, 2000);
  }

  render() {
    const {
      loading,
      showCountdown,
    } = this.state;

    return (
      <ThemeProvider theme={theme}>
        
        <div className="App">
          <Box><div class="banner"></div></Box>
          <Box  alignItems="center">
          
          
          <Box>
            <div style={{
                'fontWeight': '600',
                'fontSize': '3rem',
                'marginBottom': '10px',
              }}>
                MaiStream<br/>Maigesellschaft Stommeln<br/>
                <span style={{ fontSize: '1rem' }} >„BIRKE“ 1947 e.V.</span>
              </div>
          </Box>

          <Box mt={4}>
            { showCountdown && (
              <Paper elevation={3} style={{
                display: 'inline-block',
                position: 'relative',
                top: 20,
                padding: '25px 25px 25px 25spx',
                // margin: 20,
                maxWidth: '800px',
                width: '80%',
                // width: '80%',
              }}
                variant='outlined'
              >
                <div style={{
                  'fontWeight': '400',
                  'fontSize': '2rem',
                  'marginBottom': '10px',
                }}>
                  <Countdown date={deadline} />
                  <br/><br/>
            Wir starten am 30.04. um 19:47 Uhr. <br />
                </div>
              </Paper>
            )}
          </Box>
          
          
          <Box mt={8}>
          <div>Youtube-Channel: <b>
Maigesellschaft Birke 1947 e.V</b><br />
            <Link href="https://youtu.be/eU0auVs90GU" target="_blank" rel="noopener noreferrer" style={{ fontSize: '1.5rem' }}>https://youtu.be/eU0auVs90GU</Link> </div>
            <div class="vid">
              <iframe src="https://www.youtube.com/embed/eU0auVs90GU?vq=hd1080" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture allowfullscreen" allowfullscreen='1'></iframe>
            </div>
            
          </Box>


          <Box mt={40}>
            <div style={{
              'fontWeight': '400',
              'fontSize': '2rem',
              'marginBottom': '10px',
            }}>Ersatz-Stream</div>
            <div>Youtube-Channel: <b>
            Maigesellschaft Stommeln</b><br />
            <Link href="https://youtu.be/dPZJGELG2Ow" target="_blank" rel="noopener noreferrer" style={{ fontSize: '1.5rem' }}>https://youtu.be/dPZJGELG2Ow</Link> </div>
            <div class="vid">
              <iframe src="https://www.youtube.com/embed/dPZJGELG2Ow?vq=hd1080" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen='1'></iframe>
            </div>
            
          </Box>
          
          

            </Box>
        </div>
      </ThemeProvider>
    );
  }
}
